import Swiper, { Navigation, Pagination } from 'swiper';

import '../../node_modules/swiper/swiper.css';

const init = () => {
    
    if (document.querySelector('.section_projects-home') != null){

        let currentLink = '';
        const buttonLink = document.querySelector('.section_projects-home .button-big');

        const updateButtonLink = (swiper) => {
            let slug =  swiper.slides[swiper.activeIndex].dataset.slug;
            buttonLink.attributes.href.value = '/projects/' + slug;
        };
        
        const swiper = new Swiper('.section_projects-home .swiper', {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 10,
            loop: true,
            speed: 1000,
            loopedSlidesLimit: false,
            loppAdditionalSlides: 10,
            breakpoints: {
                767: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                    spaceBetween: 10,
                },
            },
            pagination: {
                el: '.projects-home__slider-counter',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    const newCurrent = current < 10 ? '0' + current : current;
                    const newTotal = total < 10 ? '0' + total : total;
                    return '<span class="">' + newCurrent + '</span>' + '<span class="text-size-normal">/</span>' + '<span class="text-size-normal">' + newTotal + '</span>';
                }
            },
            navigation: {
                nextEl: '.section_projects-home .slider__arrow--next',
                prevEl: '.section_projects-home .slider__arrow--prev',
            },
            on: {
                init: function (swiper) {
                    updateButtonLink(swiper);
                },
            },
        });

        swiper.on('slideChange', function ( swiper ) {
            buttonLink.classList.add('is-changing');
            updateButtonLink(swiper);
            setTimeout(function () {
                buttonLink.classList.remove('is-changing');
            }, 500);
        });

        /*
        swiper.on('slideNextTransitionStart', function (swiper) {
            buttonLink.classList.add('is-going-next');
        });

        swiper.on('slideNextTransitionEnd', function (swiper) {
            buttonLink.classList.remove('is-going-next');
        });

        swiper.on('slidePrevTransitionStart', function (swiper) {
            buttonLink.classList.add('is-going-prev');
        });

        swiper.on('slidePrevTransitionEnd', function (swiper) {
            buttonLink.classList.remove('is-going-prev');
        });
        */
            
    };
};

export default { init };