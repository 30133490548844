
const init = () => {

    let formButtons = document.querySelectorAll('[data-buttonsFieldName]');

    if(formButtons.length > 0){

        formButtons.forEach((buttonContainer) => {

            let form = buttonContainer.closest('form');
            let input = document.createElement('input');
            let buttonsChilds = buttonContainer.querySelectorAll('.button');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', buttonContainer.getAttribute('data-buttonsFieldName'));
            form.appendChild(input);
            
            let value = '';

            buttonsChilds.forEach((button) => {

                button.addEventListener('click', (e) => {
                    e.preventDefault();

                    buttonsChilds.forEach((allButton) => {
                        if(allButton != button){
                            allButton.classList.remove('is-active');
                        }
                    });

                    button.classList.add('is-active');
                    
                    if( button.classList.contains('is-active') ) {
                        value = button.innerHTML;
                    }else{
                        value = '';
                    }
                    input.setAttribute('value', value);
                });
            });
        });
    }


    let formFakeButton = document.querySelector('.form-button__cont a');

    if(formFakeButton){
        formFakeButton.addEventListener('click', (e) => {
            e.preventDefault();
            
            let form = formFakeButton.closest('form');

            form.querySelector('input[type="submit"]').click();
        });
    }
};

export default { init };