
import gsap from 'gsap';

const init = () => {

    const items = document.querySelectorAll('.team__cards > div');
    const itemsNav = document.querySelector('.team-nav');
    const dataType = 'data-field';
    let activeFilter = false;

    if( items.length <= 0 ) return;

    const selectItemsNav = (filter) => {
        
        itemsNav.querySelectorAll('a').forEach((item) => {
            if (item !== filter) {
                item.classList.remove('is-active');
            }else{
                filter.classList.toggle('is-active');
            }
        });
    }

    const filterItems = (filter, param) => {
        
        activeFilter = activeFilter != param ? param : false;

        gsap.to('.team__cards > div', { duration: 0.2, autoAlpha: 0 })

        setTimeout(() => {
            items.forEach((item) => {

                const itemParam = item.getAttribute(dataType);

                if (itemParam === activeFilter || activeFilter === false) {
                    item.classList.remove('remove');
                    item.style.display = 'block';
                } else {
                    item.style.display = 'none';
                    item.classList.add('remove');
                }
            });

            gsap.to(`.team__cards > div:not(.remove)`, { duration: 0.2, autoAlpha: 1, delay: 0.2 });
        }, 200);

        selectItemsNav(filter);
    }

    // Estraggo i paramentri
    let params = [];

    items.forEach((el, i) => {
        let param = el.getAttribute(dataType);
        params.push(param);
    });

    let paramsUnique = params.filter((v, i, a) => a.indexOf(v) === i);
    paramsUnique.sort();
    paramsUnique.reverse();

    itemsNav.innerHTML = '';

    paramsUnique.forEach((param, i) => {
        const navItem = document.createElement('a');
        navItem.classList.add(['button'], ['is-small'], ['w-button']);
        navItem.append(param);
        navItem.setAttribute(dataType, param);

        itemsNav.appendChild(navItem);

        navItem.addEventListener('click', function(e){
            filterItems(navItem, param);
        });
    });

};

export default { init };