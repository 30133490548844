
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

const init = (delay = false) => {


    let secondlineyellow = document.querySelectorAll('[data-secondlineyellow]');
    if (secondlineyellow.length > 0){
        secondlineyellow.forEach((el) => {

            let SimpleSplitText = new SplitText(el, { type: "words", wordsClass: "word" });

            SimpleSplitText.words.forEach((word, wordI) => {
                if (wordI > 0) {
                    word.classList.add('text-color-brand');
                }
            });

        });
    }


    let setyellowexceptfor = document.querySelectorAll('[data-setyellowexceptfor]');
    if (setyellowexceptfor.length > 0) {
        setyellowexceptfor.forEach((el) => {

            let SimpleSplitText = new SplitText(el, { type: "words", wordsClass: "word" });

            SimpleSplitText.words.forEach((word) => {

                let innerWord = word.innerHTML ? word.innerHTML.toLowerCase() : '';
                if (innerWord !== 'limax'){
                    word.classList.add('text-color-brand');
                }
            });

        });
    }

    let productVariants = document.querySelectorAll('.product-variant');
    if (productVariants.length > 0) {
        productVariants.forEach((el) => {
            
            let imgAlt = el.querySelector('img').getAttribute('alt');
            let textCont = el.querySelector('.product-variant__text-output');

            if(!textCont || !imgAlt){
                return;
            }

            textCont.innerHTML = imgAlt;

        });
    }


    let growUpMeters = document.querySelectorAll('[data-growUpMeters]');
    if (growUpMeters.length > 0) {
        growUpMeters.forEach((el) => {

            // Find with regExp the worlds "up to" number and "m"
            let str = el.innerHTML;
            let words = str.match(/up to (\d+)\s\m/g);

            if(words){
                let position = str.indexOf(words[0]);

                str = str.replace(words[0], '');
                
                // wrap text in a span
                let span = document.createElement('span');
                span.classList.add('text-grow-up-meters');

                let spanNumber = document.createElement('span');
                spanNumber.classList.add('text-grow-up-meters__number');

                let spanMeters = document.createElement('span');
                spanMeters.classList.add('text-grow-up-meters__meters');

                let numbers = words[0].replace('up to ', '').replace(' m', '');
                span.innerHTML = 'up to ';
                spanNumber.innerHTML = numbers;
                spanMeters.innerHTML = ' m';

                span.appendChild(spanNumber);
                span.appendChild(spanMeters);

                let output = [str.slice(0, position), span, str.slice(position)].join('');

                el.innerHTML = str.slice(0, position);
                el.after(span);
                el.after(str.slice(position));
            }

        });
    }

};

export default { init };