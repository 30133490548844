import './style.css';

import barba from '@barba/core';
import gsap from 'gsap';
import AOS from 'aos';
import Rellax from 'rellax';
import Ukiyo from "ukiyojs";
import sliders from './js/sliders';
import textHacks from './js/textHacks';
import menu from './js/menuAni.js';
import scrollEvents from './js/scrollEvents.js';
import formButtons from './js/formButtons';
import teamFilters from './js/teamFilters';

let ukiyo = new Ukiyo("[data-ukiyo]");
let rellax = new Rellax('[data-rellax]');

const resetWebflow = (data) => {
    let parser = new DOMParser();
    let dom = parser.parseFromString(data.next.html, "text/html");
    let webflowPageId = $(dom).find("html").attr("data-wf-page");
    $("html").attr("data-wf-page", webflowPageId);
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
}

function onPageAfter(data, delay = false) {

  sliders.init();
  textHacks.init();
  scrollEvents.init();
  formButtons.init();
  teamFilters.init();
  AOS.refresh();


  ukiyo = new Ukiyo("[data-ukiyo]");
  rellax = new Rellax('[data-rellax]');

  // Append filter link on project link
  let projectsFilterLink = document.querySelectorAll('[data-projects-filter-link]');

  if(projectsFilterLink.length > 0){

      projectsFilterLink.forEach((link) => {
          let filter = link.getAttribute('data-projects-filter-link');
          let linkHref = link.getAttribute('href');
          link.setAttribute('href', linkHref + '?filter=' + filter);
      });
  }

  // Set current page
  if(data.next.namespace){
      document.querySelectorAll('[data-page]').forEach((el) => {
          if(el.getAttribute('data-page') == data.next.namespace){
              el.classList.add('w--current');
              el.ariaCurrent = 'page';
          }else{
              el.classList.remove('w--current');
              el.ariaCurrent = false;
          }
      });
  }

//   let lng = Weglot.getCurrentLang();
};

// barba.hooks.afterLeave((data) => {
//   ukiyo.destroy();
//     rellax.destroy();
// });

// // After all
// barba.hooks.after((data) => {
//   onPageAfter(data);
// });

// barba.hooks.beforeEnter((data) => {
//     menu.selectCurrent(data.next.namespace);
    
//     let newClasses = data.next.container.classList;
//     newClasses.remove('page-wrapper');
//     newClasses = newClasses.toString();
//     document.body.classList = newClasses;

// });
            

let first_tl = gsap.timeline({paused: true, onComplete: function() {
  gsap.set('.main-wrapper', { clearProps: "all" });
}});

first_tl
    .to('.main-wrapper', { y: '100', duration: 0.1 })
    .to('.loader__logo', { y: -100, autoAlpha: 0, duration: 0.8, ease: "power4.in" })
    .to('.loader', { duration: 0.8, 'bottom': '100%', ease: "power4.inOut" }, "-=0.4")
    .add(() => {
        //inAnimations.animateHeroElems();
    }, '-=1.2')
    .to('.main-wrapper', { y: 0, duration: 1.2, ease: "power4.out" }, "-=0.5")
    .set('.loader__container', { autoAlpha: 0})
    ;

let line_tl = null;
if(document.querySelector('.hero-line-svg')){

line_tl = gsap.timeline({paused: true});
line_tl
    .from('.dot.is-1 .dot__inner-first', { width: 0, height: 0, duration: 1, ease: "power4.out" })
    .fromTo('.hero-line-svg path',  { 'strokeDashoffset': 1000 }, { 'strokeDashoffset': 0, duration: 2, ease: "power2.inOut" }, 0)
    .from('.dot.is-2', { scale: 0, duration: 1, ease: "power4.out" }, 1.2)
    .from('.dot.is-3', { scale: 0, duration: 1, ease: "power4.out" }, 1.3);
}

window.onload = () => {

  let currentContainer = document.querySelector('[data-barba-namespace]');
  let currentPage = currentContainer.getAttribute('data-barba-namespace');

  onPageAfter({ next: { container: currentContainer, namespace: currentPage}}, 1.4);

  menu.init();

  AOS.init({
      offset: 50,
      once: true
  });

  setTimeout(() => {
      first_tl.restart()
  }, 200);

  setTimeout(() => {
    if(line_tl){
      line_tl.restart();
    }
  }, 1000);
   
};


// basic default transition (with no rules and minimal hooks)
// barba.init({
//     timeout: 5000,
//     transitions: [{
//         name: 'basic-transition',
//         leave: function(data) {

//           var done = this.async();
//           var el = data.current.container;
//           document.body.classList.add('inTransition');

//           let leave_tl = gsap.timeline({
//               onComplete: function() {

//                   document.body.classList.add('inTransition-isOut');

//                   el.remove();
//                   done();
//               }
//           });

//           leave_tl
//           .to(el ,{
//               duration: 1,
//               opacity: 0,
//               y: -100,
//               ease: "power4.in"
//           });

//         },
//         afterLeave: function(data){

//         },
//         enter: function(data) {
            
//             var done = this.async();
//             var el = data.next.container.querySelector('.main-wrapper');

//             //el.style.opacity = 1;

//             document.body.scrollTop = 0; // For Safari
//             document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

//             gsap.set(el, {
//                 y: 100,
//                 opacity: 0,
//                 onComplete: function () {
//                     resetWebflow(data); 
//                     done();
//                 }
//             });

//         },
//         after: function(data) {
            
//             var el = data.next.container.querySelector('.main-wrapper');

//             var done = this.async();

//             let enter_tl = gsap.timeline({
//                onComplete: function() {
//                     document.body.classList.remove('inTransition');
//                     document.body.classList.remove('inTransition-isOut');
//                     gsap.set(el, { clearProps: "all" });
//                     done(); 
//                 }
//             });

//             enter_tl
//             .to(el, { duration: 1, opacity: 1, y: 0, ease: "power4.out" });
//         }



//     }],
//     prevent: ({ el }) => el.classList && el.classList.contains('barbaPrevent') || el.href.includes('/#')
// });