import { gsap } from "gsap";
// import scrollEvents from "./scrollEvents";

let menuIsOpen = false;
let menuTl;

const openCloseMenu = () => {

    const body = document.querySelector('body');
    
    if(menuIsOpen === true){
        menuTl.reverse();
        menuIsOpen = false;
        body.classList.remove("menuIsOpen");
        //scrollEvents.startLenis();
        
    }else{
        menuIsOpen = true;
        body.classList.add("menuIsOpen");
        //scrollEvents.stopLenis();
    }
}

const checkForCurrent = () => {

    let menuBigItems = document.querySelectorAll('.nav-big__item-wrapper');

    menuBigItems.forEach((el) => {
        if (el.querySelector('.submenu__link.w--current')){
            el.classList.add('isOpen');
        } else {
            el.classList.remove('isOpen');
        }
    });

}

// Barba hack
const selectCurrent = (namespace = 'home') => {
  document.querySelectorAll('.w--current').forEach((el) => el.classList.remove('w--current') );

  if (document.querySelector("[data-page='" + namespace + "']")) {
    document.querySelectorAll("[data-page='" + namespace + "']").forEach((el) => {
        el.classList.add('w--current');
    });
    }
    checkForCurrent();
}


const init = () => {

    const menuBigItems = document.querySelectorAll('.nav-big__item-wrapper');
    const menuLinks = document.querySelectorAll('.main-menu a:not(.nav-big__link-big):not(.nav-big__menu-trigger), .navbar .logo');

    menuTl = gsap.timeline({paused: true, onComplete: () => {
        checkForCurrent();
    }});
    
    let mql = window.matchMedia("(max-width: 568px)");
    
    menuTl
        .to('.main-menu', {display: 'block', autoAlpha: 1, duration: 0.1 })
        .fromTo('.main-menu__bg', { width: '0%' }, { duration: 1.4, width: '100%', ease:"power3.inOut" })

        .fromTo('.main-menu__bg--2', { width: '0%' }, { duration: 1.4, width: '50%', ease: "power3.inOut" }, 0.1)
        .fromTo('.main-menu__side-right-bg', { width: '0%' }, { duration: 1.4, width: '100%', ease: "power3.inOut" },0.1)

        if (mql.matches === true){
            menuTl
                .fromTo('.main-menu__ext-link-bg--white', { width: 0 }, { duration: 1.4, ease: "power3.inOut", width: '100%' }, 0.1)
                .fromTo('.main-menu__ext-link-bg--yellow', { width: 0 }, { duration: 1.4, ease: "power3.inOut", width: '100%' }, 0.1)
        }

        menuTl
        .fromTo('.nav-big__item-wrapper', { autoAlpha: 0, x: '100px' }, { duration: 0.8, ease: "power3.out", autoAlpha: 1, x: 0, stagger: 0.05 }, '-=0.4')
        .fromTo('.main-menu__side-right-inner > div', { autoAlpha: 0, x: '50px' }, { duration: 0.8, ease: "power3.out", autoAlpha: 1, x: 0, stagger: 0.05 }, '-=0.6')
        .fromTo('.main-menu__nav-small-bottom a', { autoAlpha: 0, x: '50px' }, { duration: 0.8, ease: "power3.out", autoAlpha: 1, x: 0, stagger: 0.05 }, '-=0.8')

        if (mql.matches !== true) {
            menuTl
            .fromTo('.main-menu__ext-link-bg--white', { width: 0 }, { duration: 1.2, ease: "power3.inOut", width: '100%' }, '-=1.5')
            .fromTo('.main-menu__ext-link-bg--yellow', { width: 0 }, { duration: 1.2, ease: "power3.inOut", width: '100%' }, '-=0.8')
        }

        menuTl
        .fromTo('.main-menu__ext-links-cont a', { autoAlpha: 0, x: '50px' }, { duration: 1, ease: "power3.out", autoAlpha: 1, x: 0, stagger: 0.05 }, '-=0.6')
    ;

    const menuTrigger = document.querySelector('.menu-hamb');

    menuTrigger.addEventListener('click', function(){

        menuTl.timeScale(1);
        menuTl.play();
            
        openCloseMenu();
    });

    if (menuBigItems){
        menuBigItems.forEach((el) => {
            let toOpen = false;
            let trigger = el.querySelector('.nav-big__item');

            trigger.addEventListener('click', function(){

                toOpen = !toOpen;

                if (toOpen === true){
                    el.classList.add('isOpen');
                } else {
                    el.classList.remove('isOpen');
                }
            });
        });
    }



    menuLinks.forEach((el) => {
        el.addEventListener('click', function (e) {

            if (menuIsOpen === false) return;

            menuTl.timeScale(2);
            openCloseMenu();

            let page = el.getAttribute('data-page');
            selectCurrent(page);
        });
    });

    checkForCurrent();
}

export default { init, selectCurrent, checkForCurrent };

/*
window.addEventListener('resize', function(){
    menuTl.revert();
});
*/